import { TbCalendar, TbVideoPlus } from "react-icons/tb";

//=====================signUpForm==================
export const GENDER_OPTIONS = [
  { value: 1, label: "Male" },
  { value: 3, label: "Other" },
  { value: 2, label: "Female" },
];
export const meetingOptions = [
  { label: "Schedule in Calendar", value: 1, icon: <TbCalendar size={28}/> },
  { label: "Start an instant meeting", value: 2, icon: <TbVideoPlus size={28} /> },
];
//=====================signInAPI==================
export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const SIGN_UP_URL = "/sign-up";
export const GET_MEETINGS = "/meetings";
export const EPISODES_URL = "/episodes";
export const DASHBOARD_URL = "/dashboard";
export const GET_EPISODES_URL = "/episodes";
export const GET_MEETING_BY_ID = "/meetings";
export const EDIT_PROFILE_URL = "/edit-user";
export const EDIT_EPISODES_URL = "/episodes";
export const CREATE_MEETING_URL = "/meetings";
export const RESET_PASSWORD_URL = "/reset-password";
export const AUTH_CHANGE_PASSWORD_URL = "/change-password/";
export const USER_PROFILE_URL = "/logged-in-data";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const USER_NOTIFICATIONS_URL = "/user-notifications";
export const GET_AGORA_TOKEN = "/generate-agora-access-token";
export const SIGN_UP_OTP_VERIFICATION_URL = "/otp-verification";
export const UPDATE_MEETING_TIME = "/meeting/update-meeting-time";
export const UPDATE_MEETING_STATUS = "/meeting/update-meeting-status";
export const UPDATE_MEETING_ENDTIME = "/meeting/update-meeting-end-datetime";
export const FORGOT_PASSWORD_VERIFY_URL = "/forgot-password-otp-verification";
export const RANDOM_VIDEOS_STARTERS_QUESTIONS_FOR_EPISODE =
  "/dropdown?dropdown_names=videos,conversation_starters,this_that_questions";
export const POST_THIS_THAT_ANSWERS =
  "/meeting/update-answer-this-that-question";
export const GET_THIS_THAT_ANSWERS = "/meeting/get-answer-this-that-question";
export const GET_MEETINGS_PAGINATED =
  "/meetings?_page_index=1&_page_size=200&_sort_order[columnKey]=id& _sort_order[order]=descend";
export const GET_LATEST_TIBIT =
  "/meetings?_page_index=10&_page_size=10&_sort_order[columnKey]=meeting_datetime& _sort_order[order]=ascend&status=1&is_expired=false";

// survey ------
export const GET_ALL_SURVEY = "/surveys";
export const STORE_SURVEY_RESPONSE = "/survey/storeResponse";

//=================StatusCodes===================
export const FORBIDDEN_CODE = 403;
export const SUCCESS_CODES = [200, 201];
export const PRECONDITION_REQUIRED_CODE = 428;
export const UNAVAILABLE_FOR_LEGAL_REASONS_CODE = 451;
