import InputField from "../InputField";
import { FiLink } from "react-icons/fi";
import React, { useState } from "react";
import ProfileCard from "../ProfileCard";
import { FaPlus } from "react-icons/fa6";
import UpComingTibits from "../UpComingTibits";
import TimePickerTibit from "../TimePickerTibit";
import SetTibitDateCalender from "../SetTibitDateCalender";

const RightBar = () => {
  const [joinLink, setJoinLink] = useState(null);

  const handleJoinLinkChange = (event) => {
    setJoinLink(event.target.value);
  };

  const handleEnterPress = (event) => {
    if (joinLink) {
      window.open(joinLink, "_blank");
    }
  };
  return (
    <React.Fragment>
      <div className="my-3 me-2 rounded-5 overflow-hidden bg-white"style={{height:"98%"}}>
        <div className="d-md-block d-none overflow-hidden bg-white rounded-5 mb-2">
          <ProfileCard />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-2 px-2 ">
          <span className="bg-primary rounded-circle d-flex justify-content-center align-items-center p-2 me-2">
            <FaPlus color="white" size={40} />
          </span>
          <div className="w-100">
            <InputField
              type="url"
              value={joinLink}
              icon={<FiLink />}
              className="w-100 border mb-0"
              placeholder="Enter tibit link"
              style={{ paddingLeft: "35px" }}
              handleKeyPress={handleEnterPress}
              onChangeHandle={handleJoinLinkChange}
            />
          </div>
        </div>
        <div
          className="overflow-y-scroll d-flex justify-content-center align-items-center"
          style={{ maxHeight: "93vh" }}
        >
          {/* <div className="my-3 d-flex w-100 justify-content-center d-none">
            <SetTibitDateCalender />
          </div>
          <div className="my-3 d-flex w-100 justify-content-center d-none">
            <TimePickerTibit />
          </div> */}
          <div className="w-100 ms-2">
            <UpComingTibits />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightBar;
