import React, { memo } from "react";
import Function from "../../utils/functions";

const Tibit = (props) => {
  const { profileImg, profileName, lastSeen, meetingTitle, meetingDateTime } =
    props;

  const meetingDate = Function.convertUTCToLocal(meetingDateTime);
  const FormateDateTime = new Date(meetingDate);

  const currentDate = new Date();
  const today = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const formattedDate =
    FormateDateTime >= today && FormateDateTime < tomorrow
      ? "Today"
      : FormateDateTime >= tomorrow &&
        FormateDateTime < new Date(tomorrow.getTime() + 24 * 60 * 60 * 1000)
      ? "Tomorrow"
      : FormateDateTime.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

  const time = FormateDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
      <div className="d-flex align-items-center justify-content-start">
        <div className="flex justify-between">
          <h6
            className="fw-bold mb-2"
            style={{
              width: "200px",
              WebkitLineClamp: 1,
              overflow: "hidden",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
            }}
          >
            <span className="fw-bold">Title:</span>{" "}
            <span className="textGray">{meetingTitle}</span>
          </h6>
          <span
            className="small mb-2"
            style={{
              width: "200px",
              WebkitLineClamp: 1,
              overflow: "hidden",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
            }}
          >
            <span className="fw-bold">with:</span> {profileName}
          </span>
          <p className="small mb-1 textGray">{formattedDate}</p>
          <p className="small textGray">{time}</p>
        </div>
      </div>
      <div
        className="d-flex flex-column align-items-end h-100"
        style={{ width: "200px" }}
      >
        <div
          className="rounded-circle shadow-sm bgProperties d-flex flex-column justify-content-between mb-3"
          style={{
            backgroundImage: `url(${profileImg})`,
            width: "45px",
            height: "45px",
          }}
        ></div>
        <p className="small textGray mb-0"> {lastSeen}</p>
      </div>
    </div>
  );
};

export default memo(Tibit);
