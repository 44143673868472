import React, { memo } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ModalPopUp = (props) => {
  const { onClose, isOpen, modalTitle, modalBody, FooterButton, modalSize } =
    props;

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={onClose}
      className="rounded-5 editModel "
      size={modalSize || "lg"}
      style={{ borderRadius: "16px" }}
    >
      <ModalHeader className="textOrange fw-bold border-0 p-2 position-relative borderTopRightRadius borderTopLeftRadius">
        <div className="d-flex justify-content-between h3 p-3 fw-bold mb-0">
          {modalTitle}
        </div>
        <Button
          onClick={onClose}
           className="position-absolute p-0 border-0 textOrange bg-transparent fs-2"
          style={{ right: "10px", top: "0px" }}
        >
          <IoCloseCircle  size={50}/>
        </Button>
      </ModalHeader>
      <ModalBody className="border-0 pb-0">{modalBody}</ModalBody>
      <ModalFooter className="border-0 text-center borderBottomLeftRadius borderBottomRightRadius">
        <div className="w-100">{FooterButton}</div>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ModalPopUp);
