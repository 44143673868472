import "./BtnStyle.scss";
import { Button } from "reactstrap";
import React, { useState } from "react";

const OutlineBtn = (props) => {
  const {
    text,
    icon,
    style,
    className,
    handleOnClick,
    disabled = false,
  } = props;

  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUpOrLeave = () => {
    setIsPressed(false);
  };

  return (
    <Button
      style={style}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpOrLeave}
      onMouseLeave={handleMouseUpOrLeave}
      onClick={handleOnClick}
      className={`OutlineBtn ${isPressed ? "pressed" : ""} ${className}`}
    >
      <div className="d-flex align-items-center  justify-content-center">
        <span> {icon && <span className="icon me-2 text-white">{icon}</span>}</span>
        <span> {text}</span>
      </div>
    </Button>
  );
};

export default React.memo(OutlineBtn);
