import ModalPopUp from "../ModalPopUp";
import { Col, Row } from "reactstrap";
import Styles from "./style.module.scss";
import { IoLogOut } from "react-icons/io5";
import { LuKeyRound } from "react-icons/lu";
import OutlineBtn from "../Buttons/OutlineBtn";
import { GoBell, GoGear } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import Images from "../../HelperMethods/Constants/ImgConstants";
import { customLogout } from "../../Redux/features/User/userSlice";
import NewPasswordForm from "../../Pages/Auth/NewPassword/NewPasswordForm";

const ProfileCard = () => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();

  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [settingPopup, setSettingPopup] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const user = useSelector((state) => state.user.user) || {};
  const [ChangePasswordPopup, setChangePasswordPopup] = useState(false);

  useEffect(() => {
    if (user) {
      const imagePath =
        user.profile_picture_path &&
        `${user.profile_picture_path}?t=${new Date().getTime()}`;
      setBackgroundImage(imagePath);
    }
  }, [user]);
  const handleLogoutClick = () => {
    dispatch(customLogout());
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSettingPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      setCurrentTime(
        now.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      );
      setCurrentDate(
        now.toLocaleDateString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      );
    };

    updateDateTime();
    const timer = setInterval(updateDateTime, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Row
      className={`py-1 align-items-start justify-content-between bgProperties h-100 rounded-5 ${Styles.RightBar}`}
      style={{
        backgroundImage: `url('${Images.PROFILE_BG_IMG}')`,
      }}
    >
      <Col xs={3} className="d-flex  mt-2 justify-content-center px-0">
        <div className="position-relative d-flex justify-content-end w-100">
          <div className="d-flex">
            {/* <OutlineBtn
              handleOnClick=""
              className="rounded-3 me-2 px-2 border-0 bg-white"
              text={<GoBell className="textDarkBlue fs-6 icon" />}
            /> */}

            <OutlineBtn
              handleOnClick={() => setSettingPopup(!settingPopup)}
              className="rounded-3 px-2 border-0 bg-white"
              text={<GoGear className="fs-6 textDarkBlue icon" />}
            />
          </div>
          {settingPopup && (
            <div
              ref={popupRef}
              className={`position-absolute badge rounded-4 px-0 bg-white orangeBorder boxShadowDark d-flex flex-column fs-6 p-2 ${Styles.modalPopup}`}
            >
              <OutlineBtn
                handleOnClick={() =>
                  setChangePasswordPopup(!ChangePasswordPopup)
                }
                className="rounded-1 border-0 shadow-0 py-1 px-2 mx-2 my-1 bg-white"
                style={{ boxShadow: "none" }}
                text={
                  <p className="small mb-0 ">
                    <LuKeyRound className="fs-5 me-2 textOrange icon" />
                    Change Password
                  </p>
                }
              />
              <OutlineBtn
                handleOnClick={handleLogoutClick}
                className="rounded-1 border-0 shadow-0 py-1 px-2 mx-2 my-1 text-start bg-white pe-3"
                style={{ boxShadow: "none" }}
                text={
                  <p className="small mb-0 pe-5">
                    <IoLogOut className="fs-5 me-2 textOrange icon" />
                    Logout
                  </p>
                }
              />
            </div>
          )}
          {ChangePasswordPopup && (
            <ModalPopUp
              isOpen={"show"}
              onClose={() => setChangePasswordPopup(false)}
              modalTitle={"Change Password"}
              modalBody={
                <NewPasswordForm
                  textOrange={"textOrange"}
                  setChangePasswordPopup={setChangePasswordPopup}
                />
              }
              FooterButton={<></>}
            />
          )}
        </div>
      </Col>

      <Col xs={6} className=" d-flex align-items-end px-0">
        <div className="w-100 py-4">
          <h6 className="fw-bold text-center">{user.name}</h6>
          <p className="small mb-3 text-center">{user.email}</p>
          <h4 className="fw-bold text-center">{currentTime}</h4>
          <p className="text-center">{currentDate}</p>
        </div>
      </Col>

      <Col xs={3} className=" d-flex align-items-start">
        <div
          className={`rounded-circle bgProperties bgOrange mt-3 ${Styles.profileImage}`}
          style={{
            display: "flex",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={backgroundImage ? backgroundImage : Images?.USER_DUMMY_IMG}
            alt="Profile"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ProfileCard;
