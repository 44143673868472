import * as Yup from "yup";

// Helper function to ensure no multiple consecutive spaces
const noMultipleSpaces = Yup.string()
  .test(
    "no-multiple-spaces",
    "Multiple consecutive spaces are not allowed",
    (value) => {
      return !/\s{2,}/.test(value || "");
    }
  )
  .test("not-blank", "This field cannot be blank", (value) => {
    return (value || "").trim().length > 0;
  });

// Validation for "About Me" section
const about_meValidation = Yup.string()
  // .matches(/^[A-Za-z .-]+$/, "Invalid Input")
  .min(2, "Bio too short")
  .max(200, "Bio should not exceed 200 characters")
  .concat(noMultipleSpaces);

// Validation for full name
const nameValidation = Yup.string()
  // .matches(/^[A-Za-z .-]+$/, "Invalid Name")
  .min(2, "Name too short")
  .max(50, "Name too long")
  .required("Name required")
  .concat(noMultipleSpaces);

const remarks = Yup.string().max(
  300,
  "Remarks should not exceed 300 characters"
);

const TibitDescription = Yup.string().max(
  300,
  "Tibit description should not exceed 300 characters"
);

// Validation for first name
const firstNameValidation = Yup.string()
  .matches(/^[A-Za-z .-]+$/, "Invalid name")
  .min(2, "Name too short")
  .max(50, "Name too long")
  .required("First name required")
  .concat(noMultipleSpaces);

// Validation for last name
const lastNameValidation = Yup.string()
  .matches(/^[A-Za-z .-]+$/, "Invalid last name")
  .min(2, "Name too short")
  .max(50, "Name too long")
  // .required("Last name required")
  .concat(noMultipleSpaces);

// Validation for state
const stateValidation = Yup.string()
  .matches(/^[A-Za-z ]+$/, "Invalid state")
  .min(2, "State name too short")
  .max(50, "State name too long")
  .required("State required")
  .concat(noMultipleSpaces);

// Validation for city
const cityValidation = Yup.string()
  .matches(/^[A-Za-z ]+$/, "Invalid city")
  .min(2, "City name too short")
  .max(50, "City name too long")
  .required("City name required")
  .concat(noMultipleSpaces);

// Validation for zip code
const zipValidation = Yup.string()
  .matches(/^\d{5}$/, "Invalid zip code")
  .required("Zip code required")
  .concat(noMultipleSpaces);

// Validation for email
const emailValidation = Yup.string()
  .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email")
  .required("Email required")
  .concat(noMultipleSpaces);

// Validation for current password
const currentPasswordValidation = Yup.string()
  .min(
    8,
    "Password must be at least 8 characters containing uppercase, lowercase, number, and symbol."
  )
  .max(40, "Password must be at most 40 characters long.")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,40}$/,
    "Password must be at least 8 characters containing uppercase, lowercase, number, and symbol."
  )
  .required("Previous password required")
  .concat(noMultipleSpaces);

// Validation for new password
const newPasswordValidation = Yup.string()
  .min(
    8,
    "Password must be at least 8 characters containing uppercase, lowercase, number, and symbol."
  )
  .max(40, "Password must be at most 40 characters long.")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,40}$/,
    "Password must be at least 8 characters containing uppercase, lowercase, number, and symbol."
  )
  .required("Password required")
  .concat(noMultipleSpaces);

// Validation for confirming the new password
const confirmNewPasswordValidation = Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Confirm password required")
  .concat(noMultipleSpaces);

// Validation for date of birth
const dobValidation = Yup.date()
  .max(new Date(), "Date of Birth cannot be in the future")
  .required("Date of birth required");

// Validation for gender
const genderValidation = Yup.number().required("Gender required");

// General required field validation
const requiredValidation = Yup.string()
  .required("Required")
  .concat(noMultipleSpaces);

// Validation for terms and conditions
const term_and_conditionValidation = Yup.bool()
  .oneOf([true], "Terms and conditions must be accepted")
  .required("Terms and conditions must be accepted");

// Exporting validation schemas
export const SIGNIN_VALIDATION_SCHEMA = Yup.object().shape({
  email: emailValidation,
  password: newPasswordValidation,
});

export const GUEST_VALIDATION_SCHEMA = Yup.object().shape({
  GuestName: nameValidation,
});

export const CREATE_MEETING_VALIDATION_SCHEMA = Yup.object().shape({
  title: requiredValidation,
  description: TibitDescription,
});

export const EDIT_PROFILE_VALIDATION_SCHEMA = Yup.object().shape({
  zip: zipValidation,
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  city: cityValidation,
  state: stateValidation,
  about_me: about_meValidation,
});

export const SIGNUP_VALIDATION_SCHEMA = Yup.object().shape({
  remarks: remarks,
  zip: zipValidation,
  dob: dobValidation,
  city: cityValidation,
  state: stateValidation,
  email: emailValidation,
  gender: genderValidation,
  last_name: lastNameValidation,
  password: newPasswordValidation,
  first_name: firstNameValidation,
  confirm_password: confirmNewPasswordValidation,
  term_and_condition: term_and_conditionValidation,
});

export const CHANGE_PASSWORD_SCHEMA = Yup.object().shape({
  password: newPasswordValidation,
  previous_password: currentPasswordValidation,
  confirm_password: confirmNewPasswordValidation,
});

export const FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
  email: emailValidation,
});

export const PASSWORD_SCHEMA = Yup.object().shape({
  password: newPasswordValidation,
  confirm_password: confirmNewPasswordValidation,
});
