import { lazy } from "react";

const routes = [
  {
    path: "/",
    component: lazy(() => import("../Pages/Auth/SignIn/SignInWrapper")),
    isPublic: true,
    exact: true,
  },
  {
    path: "/scheduleTibit/:meetingId",
    component: lazy(() => import("../Pages/Guest")),
    // isPublic: true,
    exact: true,
  },
  // {
  //   path: "/guestTibits",
  //   component: lazy(() => import("../Pages/Guest/GuestWaiting")),
  //   isPublic: true,
  //   exact: true,
  // },
  {
    path: "/signIn",
    component: lazy(() => import("../Pages/Auth/SignIn/SignInWrapper")),
    isPublic: true,
    isAuth: true,
    exact: true,
  },
  {
    path: "/profile",
    component: lazy(() => import("../Pages/UserProfile")),
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    path: "/upcomingTibits",
    component: lazy(() => import("../Pages/Auth/UpcomingTibits")),
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    path: "/eventDetails",
    component: lazy(() => import("../Pages/EventDetails")),
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    path: "/createAccount",
    component: lazy(() =>
      import("../Pages/Auth/CreateAccount/CreateAccountWrapper")
    ),
    isPublic: true,
    isAuth: true,
    exact: true,
  },
  {
    path: "/verifyOTP/:type",
    component: lazy(() => import("../Pages/Auth/verifyOtp/OtpWrapper")),
    isPublic: true,
    isAuth: true,
    exact: true,
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../Pages/Dashboard")),
    isPublic: false,
    isAuth: true,
    exact: true,
  },
  {
    path: "/tibitCalendar",
    component: lazy(() => import("../Pages/TibitCalendar")),
    isPublic: false,
    isAuth: true,
    exact: true,
  },
  {
    path: "/meeting",
    component: lazy(() => import("../Pages/Meeting")),
    // isPublic: false,
    // isAuth: false,
    exact: true,
  },
  {
    path: "/forgotPassword",
    component: lazy(() =>
      import("../Pages/Auth/ForgotPassword/ForgotPasswordWrapper")
    ),
    isPublic: true,
    isAuth: true,
    exact: true,
  },
  {
    path: "/changePassword",
    component: lazy(() =>
      import("../Pages/Auth/NewPassword/NewPasswordWrapper")
    ),
    isPublic: false,
    isAuth: false,
    exact: true,
  },
  {
    path: "/tibitSurvey",
    component: lazy(() => import("../Pages/Auth/SurveyPage")),
    isPublic: true,
    isAuth: true,
    exact: true,
  },
];

export default routes;
