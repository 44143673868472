import "./styles.scss";
import React, { useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { Card, CardBody, CardHeader } from "reactstrap";

const SetTibitDateCalender = () => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    console.log(ranges);
    setSelectionRange(ranges.selection);
  };

  return (
    <Card className="rounded-4 orangeBorder boxShadowDark overflow-hidden">
      <CardHeader className="border-0 bg-transparent px-3 py-2 h5 fw-bold mb-0">
        Date set
      </CardHeader>
      <CardBody className="p-0">
        <DateRangePicker
          onChange={handleSelect}
          ranges={[selectionRange]}
          className="borderRadius-12"
          style={{ width: "346px" }}
        /> 
      </CardBody>
    </Card>
  );
};

export default SetTibitDateCalender;
