let Images = {
  // =============================landingPageImgs===================================

  LOGO_IMG: require("../../Assets/Images/logo.png"),
  SHORT_LOGO_IMG: require("../../Assets/Images/shortLogo.png"),

  // =============================create account page===================================
  USER_DUMMY_IMG: require("../../Assets/Images/dummyUser.png"),
  TICTOK_IMG: require("../../Assets/Images/socialMedia/ticktock.png"),
  FACEBOOK_IMG: require("../../Assets/Images/socialMedia/facebook.png"),
  INSTAGRAM_IMG: require("../../Assets/Images/socialMedia/instagram.png"),
  CREATE_ACCOUNT_IMG: require("../../Assets/Images/createAccount/CreateAccountImg.png"),
  //==============================Icons===========================================
  EDIT_ICON: require("../../Assets/Images/icons/tabler_edit.png"),
  //==============================tempImages===========================================
  HEART_IMG: require("../../Assets/Images/icons/heart.png"),
  G_ICON_IMG: require("../../Assets/Images/icons/GIcon.png"),

  // ===============================calendar icons =================================
  ERROR_404_IMG: require("../../Assets/Images/error404.png"),
  OUTLOOK_CALENDAR_IMG: require("../../Assets/Images/outlook.png"),
  I_CLOUD_CALENDAR_IMG: require("../../Assets/Images/calendar.png"),
  BUTTON_ARROW_IMG: require("../../Assets/Images/arrow3d copy.png"),
  GOOGLE_CALENDAR_IMG: require("../../Assets/Images/google_calendar_icon.png"),
  //======================================plants============================================
  PLANT_ONE_IMG: require("../../Assets/Images/plantOne.png"),
  PLANT_TWO_IMG: require("../../Assets/Images/plantTwo.png"),
  TOP_RECTANGLE_IMG: require("../../Assets/Images/TopRectangle.png"),
  PROFILE_BG_IMG: require("../../Assets/Images/Profile/profileBg.png"),
};

export default Images;
